<template>
  <div class="h-full flex flex-col justify-center">
    <h1 class="text-5xl font-medium text-white">Dupbit.</h1>
  </div>
</template>

<script>

export default {
    name: 'Home',
};
</script>
